import { useEffect } from 'react'
import Seo from 'src/components/seo/Seo'

function Atendimento() {
  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    window.location.href = 'https://decathlon-br.zendesk.com/hc/pt-br'
  }, [])

  const IframeAtendimento = () => (
    <>
      <iframe
        title="Atendimento"
        className="w-full block overflow-hidden border-0 min-h-screen iframe-atendimento"
        src="https://decathlon.custhelp.com/app/home"
        height="173.611vw"
      />
    </>
  )

  return (
    <>
      <Seo language="pt-br" title="Atendimento | Decathlon" />
      <IframeAtendimento />
    </>
  )
}

export async function getServerData() {
  return {
    status: 307,
    headers: {
      Location: 'https://decathlon-br.zendesk.com/hc/pt-br',
    },
  }
}

export default Atendimento
